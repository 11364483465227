import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

const RewardsAwarded = ({ data }) => {
    // Check if data is defined
    if (!data) {
        return (
            <Card className="black-card py-3">
                <Card.Body
                    className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                    <h3 className="text-lg font-weight-light text-uppercase tracking-wide mb-1">Rewards Awarded</h3>
                    <div className="px-2 block text-sm font-weight-light leading-snug tracking-wide">Chart generation failed: Insufficient Data</div>
                </Card.Body>
            </Card>
        );
    }

    const generateChartOptions = () => {
        // Prepare series data by converting points to USD
        // Each value is divided by 100 because 100 points are equivalent to 1 USD
        const standardRewards = data.map(data => data.standard / 100);
        const bonusRewards = data.map(data => data.bonus / 100);

        // Prepare categories using month labels (e.g., "AUG 24", "SEP 24")
        const categories = data.map(data => data.month_year_label);

        return {
            series: [
                {
                    name: 'Standard',
                    data: standardRewards,
                    color: '#B38840',
                },
                {
                    name: 'Bonus',
                    data: bonusRewards,
                    color: '#FFFFFF',
                }
            ],
            options: {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                    events: {
                        mounted: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#rewards-awarded-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        },
                        updated: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#rewards-awarded-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: false,
                },
                title: {
                    text: 'REWARDS AWARDED (USD)',
                    align: 'center',
                    style: {
                        fontSize: '18px',
                        fontWeight: 'light',
                        color: '#FFFFFF',
                        fontFamily: 'Inter, sans-serif',
                    },
                },
                xaxis: {
                    categories: categories,
                    labels: {
                        formatter: function (value) {
                            return value;
                        },
                        style: {
                            colors: '#FFFFFF',
                            fontSize: '13px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    axisTicks: {
                        show: true,
                        color: '#FFFFFF',
                        height: 8,
                    },
                    axisBorder: {
                        show: true,
                        color: '#FFFFFF',
                    },
                    crosshairs: {
                        show: false
                    },
                    tooltip: {
                        enabled: false
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            // Convert large numbers to human-readable format like "100K"
                            if (value >= 1000) {
                                return '$' + (value / 1000).toFixed(0) + 'K'; // Convert to K
                            }

                            return '$' + value; // Return value for numbers smaller than 1000
                        },
                        style: {
                            colors: '#FFFFFF',
                            fontSize: '14px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    title: {
                        text: undefined,
                    },
                    axisBorder: {
                        show: true,
                        color: '#FFFFFF',
                        offsetX: -1,
                        offsetY: 0
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                grid: {
                    show: false, // Disable both horizontal and vertical grid lines
                },
                fill: {
                    opacity: 1,
                    colors: ['#A67C00', '#FFFFFF'],
                },
                tooltip: {
                    theme: 'dark',
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Arial',
                        color: '#FFFFFF',
                    },
                    fillSeriesColor: false,
                    marker: {
                        show: true,
                    },
                    x: {
                        show: true,
                    },
                    y: {
                        formatter: function (value) {
                            return '$' + value.toLocaleString();
                        },
                    },
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    labels: {
                        colors: ['#FFFFFF', '#FFFFFF'],
                    },
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                }
            },
        }
    };

    // Get the chart options for this building
    const chartOptions = generateChartOptions();

    return (
        <Card className="black-card">
            <Card.Body className="text-white px-2 pt-3 pb-0">
                <Chart options={chartOptions.options} series={chartOptions.series} type="bar" height="330" className="d-block m-auto" id="rewards-awarded-chart"/>
            </Card.Body>
        </Card>
    );
}

// Define the prop types for the component
RewardsAwarded.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            month_year_label: PropTypes.string.isRequired,
            standard: PropTypes.number.isRequired,
            bonus: PropTypes.number.isRequired,
        })
    ),
};

export default RewardsAwarded

// Import necessary modules and hooks from React
import React, { useEffect, useRef, useState } from 'react';

// Import custom Panel component
import Panel from '../../panel';

// Import a custom NavLink component for building details
import BuildingDetailsNavlink from "../common/BuildingDetailsNavlink";

// Hook for accessing URL parameters
import { useParams } from "react-router-dom";

// Import constants used in the component
import constants from "../../../../../component/constants";

// Import Redux hook for accessing the global state
import { useSelector } from "react-redux";

// Import moment.js for date manipulation
import moment from 'moment';

// Import DatePicker component and its styles
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Import custom styles
import '../../../../../styles/multi-residential.css';
import '../../../../../styles/datepicker.css';

// Import the custom components used for rendering tables and charts
import BuildingLedgerTable from "./components/BuildingLedgerTable";

import RentalIncome from "./charts/RentalIncome";
import TotalExpense from "./charts/TotalExpense";
import NetOperatingIncome from "./charts/NetOperatingIncome";
import VacancyLoss from "./charts/VacancyLoss";
import BuildingLedgerExport from "./components/BuildingLedgerExport";
import BuildingInquiry from "../common/BuildingInquiry";
import BuildingErrorCard from "../common/BuildingErrorCard";

// Import the notification component and styles
import ReactNotifications from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import BuildingChartsExport from "../common/BuildingChartsExport";

const BuildingFinancialDetails = (props) => {
    // Extract buildingId from URL parameters
    const { buildingId } = useParams();

    // Access application URL from constants
    const { APP_URL } = constants;

    // Get authentication data from Redux store
    const { authData, helper } = useSelector((state) => ({
        authData: state.dataPack.authData,
        helper: state.dataPack.helper
    }));

    // Get user info from authData
    const user = authData?.user;

    const [buildingError, setBuildingError] = useState(null);
    const [isBuildingLoading, setIsBuildingLoading] = useState(false);
    const [building, setBuilding] = useState(null);

    // State for managing error, loading state, buildings data, and search input
    const [ledgersError, setLedgersError] = useState(null);
    const [isLedgersLoading, setIsLedgersLoading] = useState(false);
    const [buildingLedgers, setBuildingLedgers] = useState([]);

    // States for handling building metrics data
    const [metricsError, setMetricsError] = useState(null);
    const [isMetricsLoading, setIsMetricsLoading] = useState(false);
    const [buildingMetrics, setBuildingMetrics] = useState([]);

    // Ledger date range states: start and end dates are set to 1 month ago by default
    const [ledgerStartDate, setLedgerStartDate] = useState(moment().subtract(1, 'months').startOf('month').toDate());
    const [ledgerEndDate, setLedgerEndDate] = useState(moment().subtract(1, 'months').endOf('month').toDate());

    // Metric date range states (initially null)
    const [metricsStartDate, setMetricsStartDate] = useState(null);
    const [metricsEndDate, setMetricsEndDate] = useState(null);

    // Ref to manage fetch request aborts
    const abortControllerForBuilding = useRef(null);
    const abortControllerForLedgers = useRef(null);
    const abortControllerForMetrics = useRef(null);

    // Create a ref to reference the HTML content you want to convert to PDF
    const chartsReference = useRef();

    useEffect(() => {
        const fetchBuilding = async () => {
            // Abort any previous fetch request
            abortControllerForBuilding.current?.abort();
            abortControllerForBuilding.current = new AbortController();

            // Set loading state
            setIsBuildingLoading(true);

            // Construct the URL for fetching building data with query parameters
            const buildingEndpoint = APP_URL + `buildings/${buildingId}`;

            try {
                // Fetch buildings data from API
                const response = await fetch(buildingEndpoint, {
                    signal: abortControllerForBuilding.current?.signal,
                });
                const result = await response.json();

                // Check if the API response was successful
                if (response.ok && result.status === 'success') {
                    setBuilding(result.data);  // Set the building data
                } else {
                    // If the response is not OK, handle error case
                    setBuildingError(result.message);
                }
            } catch (e) {
                if (e.name === 'AbortError') {
                    console.log('Fetch Building Aborted');
                    return;
                }

                // Set error state if fetch fails
                setBuildingError(e);
            } finally {
                // Reset loading state
                setIsBuildingLoading(false);
            }
        };

        fetchBuilding();
    }, []);

    // Effect to set document title and fetch buildings when component mounts or search changes
    useEffect(() => {
        document.title = 'Residential Financial View | RentPerks';
        const getCompleteMonth = (startDate, endDate) => {
            const start = moment(startDate);
            const end = moment(endDate);

            let firstDayOfCompleteMonth, lastDayOfCompleteMonth;

            firstDayOfCompleteMonth = start.clone().startOf('month');
            lastDayOfCompleteMonth = start.clone().endOf('month');

            // Check if the first complete month fits within the range
            if (!firstDayOfCompleteMonth.isBefore(end)) {
                // No full month in range, return null or handle the edge case as needed
                return null;
            }

            setMetricsStartDate(firstDayOfCompleteMonth.toDate());
            setMetricsEndDate(lastDayOfCompleteMonth.toDate());
        };

        // Call the get complete month function
        getCompleteMonth(ledgerStartDate, ledgerEndDate);

        const fetchBuildingLedgers = async () => {
            // Abort any previous fetch request
            abortControllerForLedgers.current?.abort();
            abortControllerForLedgers.current = new AbortController();

            // Set loading state
            setIsLedgersLoading(true);

            // Construct the URL for fetching building data with query parameters
            const formattedLedgerStartDate = moment(ledgerStartDate).format('YYYY-MM-DD');
            const formattedLedgerEndDate = moment(ledgerEndDate).format('YYYY-MM-DD');
            const buildingLedgersEndpoint = APP_URL + `buildings/${buildingId}/ledgers?startDate=${formattedLedgerStartDate}&endDate=${formattedLedgerEndDate}`;

            try {
                // Fetch buildings data from API
                const response = await fetch(buildingLedgersEndpoint, {
                    signal: abortControllerForLedgers.current?.signal,
                });
                const result = await response.json();

                // Check if the API response was successful
                if (response.ok && result.status === 'success') {
                    setBuildingLedgers(result.data);  // Set the building ledgers data
                } else {
                    // If the response is not OK, handle error case
                    setLedgersError(result.message);
                }
            } catch (e) {
                if (e.name === 'AbortError') {
                    console.log('Fetch Building Ledgers Aborted');
                    return;
                }

                // Set error state if fetch fails
                setLedgersError(e);
            } finally {
                // Reset loading state
                setIsLedgersLoading(false);
            }
        };

        // Call the fetch function
        fetchBuildingLedgers();
    }, [ledgerStartDate, ledgerEndDate]);

    useEffect(() => {
        if (!metricsStartDate || !metricsEndDate) {
            return;
        }

        const fetchBuildingMetrics = async () => {
            // Abort any previous fetch request
            abortControllerForMetrics.current?.abort();
            abortControllerForMetrics.current = new AbortController();

            // Set loading state
            setIsMetricsLoading(true);

            // Construct the URL for fetching building data with query parameters
            const formattedMetricsStartDate = moment(metricsStartDate).format('YYYY-MM-DD');
            const formattedMetricsEndDate = moment(metricsEndDate).format('YYYY-MM-DD');
            const buildingMetricsEndpoint = APP_URL + `buildings/${buildingId}/metrics?scope=financial&startDate=${formattedMetricsStartDate}&endDate=${formattedMetricsEndDate}`;

            try {
                // Fetch buildings data from API
                const response = await fetch(buildingMetricsEndpoint, {
                    signal: abortControllerForMetrics.current?.signal,
                });
                const result = await response.json();

                // Check if the API response was successful
                if (response.ok && result.status === 'success') {
                    setBuildingMetrics(result.data.financial);  // Set the building metrics data
                } else {
                    // If the response is not OK, handle error case
                    setMetricsError(result.message);
                }
            } catch (e) {
                if (e.name === 'AbortError') {
                    console.log('Fetch Building Metrics Aborted');
                    return;
                }

                // Set error state if fetch fails
                setMetricsError(e);
            } finally {
                // Reset loading state
                setIsMetricsLoading(false);
            }
        };

        // Call the fetch function
        fetchBuildingMetrics();
    }, [metricsStartDate, metricsEndDate]);

    return (
        <div className="row justify-content-center section_height">
            {/* Notifications */}
            <ReactNotifications />

            {/* Sidebar */}
            <Panel {...props} authData={authData} />

            {/* Main Content */}
            <div className="col-lg-10 col-md-10 col-sm-12 col-xl-10">
                {/* Check if there is an error. */}
                {buildingError || ledgersError || metricsError ? (
                    <BuildingErrorCard />
                ) : (
                    <>
                        <div className="row mb-10">
                            <div className="col-md-6">
                                <BuildingDetailsNavlink buildingId={buildingId}/>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4">
                                        <BuildingLedgerExport buildingId={buildingId} startDate={ledgerStartDate}
                                                              endDate={ledgerEndDate} data={buildingLedgers}/>
                                    </div>
                                    <div className="col-md-4">
                                        <BuildingChartsExport building={building} reference={chartsReference} config={{filename: 'building-financial-charts', title: 'Financial Charts'}} />
                                    </div>
                                    <div className="col-md-4">
                                        <BuildingInquiry ownerId={user.customer_id}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                {isLedgersLoading ? (
                                    // Show loader while data is loading
                                    <div className="loader"></div>
                                ) : (
                                    <BuildingLedgerTable data={buildingLedgers}/>
                                )}
                            </div>
                            <div className="col-md-6">
                                <div className="row mb-10">
                                    <div className="col-md-6">
                                        <DatePicker selected={ledgerStartDate}
                                                    onChange={(date) => setLedgerStartDate(date)}
                                                    className='text-center'/>
                                    </div>
                                    <div className="col-md-6">
                                        <DatePicker selected={ledgerEndDate}
                                                    onChange={(date) => setLedgerEndDate(date)}
                                                    className='text-center'/>
                                    </div>
                                </div>

                                {!buildingMetrics || isMetricsLoading ? (
                                    // Show loader while data is loading
                                    <div className="loader"></div>
                                ) : (
                                    <div ref={chartsReference} >
                                        <div className="row mb-10">
                                            <div className="col-md-6">
                                                <RentalIncome data={buildingMetrics.rental_income}/>
                                            </div>
                                            <div className="col-md-6">
                                                <TotalExpense data={buildingMetrics.expense}/>
                                            </div>
                                        </div>

                                        {metricsStartDate ? (
                                            <div className="mb-10 text-center">
                                                <span className="bg-black-opacity-80 text-4xl text-white font-weight-bolder text-uppercase tracking-widest d-inline-block px-4 py-2 rounded">
                                                    {moment(metricsStartDate).format('MMMM YYYY')}
                                                </span>
                                            </div>
                                        ) : null}

                                        <div className="row">
                                            <div className="col-md-6">
                                                <NetOperatingIncome data={buildingMetrics.net_operating_income}/>
                                            </div>
                                            <div className="col-md-6">
                                                <VacancyLoss data={buildingMetrics.vacancy_loss}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export {BuildingFinancialDetails}

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Import custom components and hooks
import Panel from '../panel';
import BuildingSearchBar from './common/BuildingSearchBar';
import BuildingCard from './common/BuildingCard';
import BuildingMeetingBookings from "./common/BuildingMeetingBookings";


// Import constants and utilities
import constants from './../../../../component/constants';
import { useDebounce } from '../../../../hooks/useDebounce';
import BuildingErrorCard from "./common/BuildingErrorCard";

// Import the notification component and styles
import ReactNotifications from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const MultiResidential = (props) => {
    const { APP_URL } = constants;

    // Get authentication data from Redux store
    const { authData } = useSelector((state) => ({
        authData: state.dataPack.authData,
    }));

    // Get user info from authData
    const user = authData?.user;

    // State for managing error, loading state, buildings data, and search input
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [buildings, setBuildings] = useState([]);
    const [search, setSearch] = useState('');

    // Debounce search input to reduce API calls
    const debouncedSearch = useDebounce(search);

    // Ref to manage fetch request aborts
    const abortControllerRef = useRef(null);

    // Effect to set document title and fetch buildings when component mounts or search changes
    useEffect(() => {
        document.title = 'Multi - Residential View | RentPerks';

        const fetchBuildings = async () => {
            // Abort any previous fetch request
            abortControllerRef.current?.abort();

            abortControllerRef.current = new AbortController();

            // Set loading state
            setIsLoading(true);

            // Construct the URL for fetching buildings data with query parameters
            const buildingsEndpoint = APP_URL + `buildings?filter[ownerId]=${user.customer_id}&filter[name]=${debouncedSearch}`;

            try {
                // Fetch buildings data from API
                const response = await fetch(buildingsEndpoint, {
                    signal: abortControllerRef.current?.signal,
                });
                const result = await response.json();

                // Set buildings data
                setBuildings(result.data);
            } catch (e) {
                if (e.name === 'AbortError') {
                    console.log('Fetch Buildings Aborted');
                    return;
                }

                // Set error state if fetch fails
                setError(e);
            } finally {
                // Reset loading state
                setIsLoading(false);
            }
        };

        // Call the fetch function
        fetchBuildings();
    }, [debouncedSearch]);

    return (
        <div className="row justify-content-center section_height">
            {/* Notifications */}
            <ReactNotifications />

            {/* Sidebar */}
            <Panel {...props} authData={authData} />

            {/* Main Content */}
            <div className="col-lg-10 col-md-10 col-sm-12 col-xl-10">
                {/* Check if there is an error. */}
                {error ? (
                    <BuildingErrorCard />
                ) : (
                    <>
                        {isLoading ? (
                            // Show loader while data is loading
                            <div className="d-flex h-75 items-center justify-center">
                                <div className="loader"></div>
                            </div>
                        ) : buildings.length === 0 ? (
                            // Show message if no buildings are found
                            <div className="d-flex items-center justify-center p-4 rounded-lg bg_black_opacity">
                                <h3 className="text-white mb-0">No Buildings Found</h3>
                            </div>
                        ) : (
                            <>
                                <div className="grid grid-cols-12 gap-8 mb-10">
                                    {/* Search Bar */}
                                    <div className="col-span-9">
                                        <BuildingSearchBar onChange={setSearch}/>
                                    </div>

                                    {/* Book a Meeting */}
                                    <div className="col-span-3">
                                        <BuildingMeetingBookings customer={user} buildings={buildings}/>
                                    </div>
                                </div>

                                {/* Show building cards */}
                                <div className="grid grid-cols-12 gap-8">
                                    {buildings.map((building) => (
                                        <div key={building.id} className="col-span-4">
                                            <Link to={`/multi-residential/${building.id}/financial`}>
                                                <BuildingCard building={building}/>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default MultiResidential;

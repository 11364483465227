import React, {useEffect, useRef, useState} from "react";

// Import moment.js for date manipulation
import moment from 'moment';
import 'moment-timezone';

// Import constants used in the component
import constants from "../../../../../../../component/constants";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BuildingSearchBar from "../../BuildingSearchBar";
import BuildingBookMeeting from "../index";
import {Link} from "react-router-dom";
import BuildingCard from "../../BuildingCard";
import {Alert} from "react-bootstrap";

const DateTimeSlot = (props) => {
    // Access application URL from constants
    const { APP_URL } = constants;

    const buildingId = props.form.buildingId;
    const timeZone = props.form.timeZone;
    const [hasFormError, setHasFormError] = useState(false);

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [selectedDateSlots, setSelectedDateSlots] = useState([]);

    const [availableDates, setAvailableDates] = useState([]);

    // States for handling building metrics data
    const [slotsError, setSlotsError] = useState(null);
    const [isSlotsLoading, setIsSlotsLoading] = useState(false);
    const [slots, setSlots] = useState([]);

    // Ledger date range states: start and end dates are set to 1 month ago by default
    const [rangeStartDate, setRangeStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [rangeEndDate, setRangeEndDate] = useState(moment().add(2, 'months').format('YYYY-MM-DD'));

    // Ref to manage fetch request aborts
    const abortControllerForBookingSlots = useRef(null);

    useEffect(() => {
        if (!rangeStartDate || !rangeEndDate) {
            return;
        }

        const fetchBookingsSlots = async () => {
            // Abort any previous fetch request
            abortControllerForBookingSlots.current?.abort();
            abortControllerForBookingSlots.current = new AbortController();

            // Set loading state
            setIsSlotsLoading(true);

            // Construct the URL for fetching building data with query parameters
            const slotsEndpoint = APP_URL + `buildings/${buildingId}/bookings/slots?timeZone=${timeZone}&startDate=${rangeStartDate}&endDate=${rangeEndDate}`;

            try {
                // Fetch buildings data from API
                const response = await fetch(slotsEndpoint, {
                    signal: abortControllerForBookingSlots.current?.signal,
                });
                const result = await response.json();

                // Check if the API response was successful
                if (response.ok && result.status === 'success') {
                    const dates = Object.keys(result.data.slots); // Extract dates as an array
                    setAvailableDates(dates);
                    setSelectedDate(dates[0]);
                    setSelectedDateSlots(result.data.slots[dates[0]]);

                    // Set the building bookings slots
                    setSlots(result.data.slots);
                } else {
                    // If the response is not OK, handle error case
                    setSlotsError(result.message);
                }
            } catch (e) {
                if (e.name === 'AbortError') {
                    console.log('Fetch Building Bookings Slots Aborted');
                    return;
                }

                // Set error state if fetch fails
                setSlotsError(e);
            } finally {
                // Reset loading state
                setIsSlotsLoading(false);
            }
        };

        if (buildingId && timeZone) {
            // Call the fetch function
            fetchBookingsSlots();

            getAvailableTimeSlots();
        }
    }, [buildingId, timeZone, rangeStartDate, rangeEndDate]);

    // Disable dates not in availableDates
    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const formattedDate = moment(date).format('YYYY-MM-DD'); // Format date as YYYY-MM-DD
            const today = moment().format('YYYY-MM-DD'); // Format today's date as YYYY-MM-DD

            // Disable if the date is not in availableDates OR if the date is in the past
            return (
                !Array.isArray(availableDates) ||
                !availableDates.includes(formattedDate) ||
                moment(formattedDate).isBefore(today) // Check if the date is before today
            );
        }
        return false; // Do not disable for other views (e.g., year view)
    };

    // Filters out expired time slots for the selected date based on the current time in the given timezone.
    const getAvailableTimeSlots = () => {
        // Get the current time in the provided timezone
        const currentDateTime = moment.tz(timeZone);

        // Filter the slots that are still valid
        const availableTimeSlots = selectedDateSlots.filter((slot) => {
            const slotTimeInZone = moment.tz(`${selectedDate} ${slot}`, 'YYYY-MM-DD HH:mm', timeZone);
            return slotTimeInZone.isAfter(currentDateTime.clone().add(1, 'hour'));
        });

        setSelectedDateSlots(availableTimeSlots);
    };

    // Handle date selection
    const handleDateChange = (date) => {
        clearSelectedTimeSlots();

        const formattedDate = moment(date).format('YYYY-MM-DD');
        setSelectedDate(formattedDate);
        setSelectedDateSlots(slots[formattedDate]);
    };

    // Handler for navigation (next/previous month)
    const handleMonthChange = ({ activeStartDate, view }) => {
        if (view === 'month') {
            clearSelectedTimeSlots();
        }
    };

    const handleSelectedTimeSlot = (e) => {
        clearSelectedTimeSlots();

        // Set the selected time slot
        setSelectedTimeSlot(e.target.dataset.startTime);

        // Add 'primary' class to the clicked button
        e.target.classList.remove('btn-secondary');
        e.target.classList.add('btn-primary');
    };

    const clearSelectedTimeSlots = () => {
        // Get all buttons with the time slot data attribute
        const buttons = document.querySelectorAll('button[data-start-time]');

        // Reset all buttons to 'secondary'
        buttons.forEach((button) => {
            button.classList.remove('btn-primary'); // Ensure no spaces in class name
            button.classList.add('btn-secondary'); // Ensure no spaces in class name
        });

        setSelectedTimeSlot(null);
    }

    const handleNextStep = () => {
        // TODO: Refactor and enhance the form validation logic for better scalability and maintainability.
        if (!selectedDate || !selectedTimeSlot) {
            setHasFormError(true);
            return;
        }

        setHasFormError(false);

        props.update('bookingDate', selectedDate);
        props.update('bookingStartTime', selectedTimeSlot);
        props.nextStep();
    }

    return (
        <>
            {isSlotsLoading ? (
                // Show loader while data is loading
                <div className="d-flex h-72 items-center justify-center">
                    <div className="loader"></div>
                </div>
            ) : availableDates.length === 0 ? (
                // Show message if no buildings are found
                <div className="d-flex items-center justify-center p-4 rounded-lg bg_black_opacity">
                    <h3 className="text-white mb-0 text-lg">No Available Dates Found</h3>
                </div>
            ) : (
                <>
                    <div className="container-fluid">
                        {/* Display Alert only if hasError is true */}
                        {hasFormError && (
                            <Alert variant="danger">
                                <strong>Booking Validation Errors:</strong>
                                <ul className="mb-0">
                                    {!selectedDate && (
                                        <li>The booking date is required.</li>
                                    )}

                                    {!selectedTimeSlot && (
                                        <li>The booking time slot is required.</li>
                                    )}
                                </ul>
                            </Alert>
                        )}
                        <div className="row">
                            <div className="col-md-6">
                                <Calendar
                                    className="w-100 h-72"
                                    value={selectedDate}
                                    calendarType="gregory"
                                    tileDisabled={tileDisabled} // Disable unavailable dates
                                    onClickDay={handleDateChange}
                                    onActiveStartDateChange={handleMonthChange} // Handles month navigation
                                    minDate={new Date()} // Set minimum date to today's date
                                    maxDate={moment(rangeEndDate).toDate()}
                                    minDetail="month" // Prevent navigation to "Year" and "Decade" views
                                    maxDetail="month" // Restrict navigation level to months
                                    prev2Label={null} // Remove "Previous year" button
                                    next2Label={null} // Remove "Next year" button
                                />
                            </div>
                            <div className="col-md-6">
                                <div className="h-72 overflow-y-auto">
                                    <h3 className="font-weight-light text-lg text-white">
                                        {moment(selectedDate).format('MMMM D, YYYY')} {selectedTimeSlot}
                                    </h3>
                                    {
                                        selectedDateSlots.length > 0 ? (
                                            <ul className="spot-list grid grid-cols-12 gap-3">
                                                {selectedDateSlots.map((slot, index) => (
                                                    <li key={index} className="col-span-6 p-1">
                                                        <button
                                                            data-start-time={slot}
                                                            className="btn btn-secondary w-full"
                                                            onClick={handleSelectedTimeSlot}
                                                        >
                                                            {slot}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <span className="text-center text-gray-500 d-block">No available time slots.</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <button className='btn btn-secondary btn-block' onClick={props.previousStep}>Back
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button className='btn btn-primary btn-block' onClick={handleNextStep}>Continue</button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default DateTimeSlot;
